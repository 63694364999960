import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as projects from './Reducers/ProjectsReducer';
import * as categories from './Reducers/CategoriesReducer';
import * as post from './Reducers/PostReducer';

const rootReducer = combineReducers({
  // Project Reducers
  getAllProjects: projects.getAllProjectsReducer,
  getRandomProjects: projects.getRandomProjectsReducer,
  getProjectById: projects.getProjectByIdReducer,
  // categories Reducers
  getAllCategories: categories.getAllcategoriesReducer,
  // Post Reducers
  getPosts: post.getPostsReducer,
  getPostDetails: post.getPostDetailsReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
