import * as projectsConstants from "../Constants/ProjectsConstants";

// get all projects reducer
export const getAllProjectsReducer = (state = { projects: [] }, action) => {
  switch (action.type) {
    case projectsConstants.PROJECTS_LIST_REQUEST:
      return { loading: true, projects: [] };
    case projectsConstants.PROJECTS_LIST_SUCCESS:
      return {
        loading: false,
        projects: action.payload.projects,
        pageNumber: action.payload.pageNumber,
        pages: action.payload.pages,
      };
    case projectsConstants.PROJECTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// get random projects reducer
export const getRandomProjectsReducer = (state = { projects: [] }, action) => {
  switch (action.type) {
    case projectsConstants.PROJECTS_RANDOM_REQUEST:
      return { loading: true, projects: [] };
    case projectsConstants.PROJECTS_RANDOM_SUCCESS:
      return { loading: false, projects: action.payload };
    case projectsConstants.PROJECTS_RANDOM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// get project by id reducer
export const getProjectByIdReducer = (state = { project: {} }, action) => {
  switch (action.type) {
    case projectsConstants.PROJECTS_DETAILS_REQUEST:
      return { loading: true, ...state };
    case projectsConstants.PROJECTS_DETAILS_SUCCESS:
      return { loading: false, project: action.payload };
    case projectsConstants.PROJECTS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
