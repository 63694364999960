import * as types from '../Constants/BlogConstants';

export const getPostsReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    // GET POSTS REDUCER
    case types.GET_POST_REQUEST:
      return { loading: true };
    case types.GET_POST_SUCCESS:
      return {
        posts: action.payload.blogs,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case types.GET_POST_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};

// get post details reducer
export const getPostDetailsReducer = (
  state = { post: {}, related: [] },
  action
) => {
  switch (action.type) {
    case types.GET_POST_DETAILS_REQUEST:
      return { loading: true };
    case types.GET_POST_DETAILS_SUCCESS:
      return {
        loading: false,
        post: action.payload.blog,
        related: action.payload.related,
      };
    case types.GET_POST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
