export const PROJECTS_LIST_REQUEST = "PROJECTS_LIST_REQUEST";
export const PROJECTS_LIST_SUCCESS = "PROJECTS_LIST_SUCCESS";
export const PROJECTS_LIST_FAIL = "PROJECTS_LIST_FAIL";

export const PROJECTS_DETAILS_REQUEST = "PROJECTS_DETAILS_REQUEST";
export const PROJECTS_DETAILS_SUCCESS = "PROJECTS_DETAILS_SUCCESS";
export const PROJECTS_DETAILS_FAIL = "PROJECTS_DETAILS_FAIL";
export const PROJECTS_DETAILS_RESET = "PROJECTS_DETAILS_RESET";

export const PROJECTS_RANDOM_REQUEST = "PROJECTS_RANDOM_REQUEST";
export const PROJECTS_RANDOM_SUCCESS = "PROJECTS_RANDOM_SUCCESS";
export const PROJECTS_RANDOM_FAIL = "PROJECTS_RANDOM_FAIL";
