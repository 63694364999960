import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import ScrollOnTop from './ScrollOnTop';
import BigLoader from './Components/Error&Loading/BigLoader';
const HomeScreen = React.lazy(() => import('./Screens/HomeScreen'));
const AllCourses = React.lazy(() => import('./Screens/AllCourses'));
const SingleCourse = React.lazy(() => import('./Screens/SingleCourse'));
const PreviewPage = React.lazy(() => import('./Screens/PreviewPage'));
const CategoryPage = React.lazy(() => import('./Screens/CategoryPage'));
const BlogsScreen = React.lazy(() => import('./Screens/BlogsScreen'));
const BlogDetails = React.lazy(() => import('./Screens/BlogDetails'));
const PrivacyPolicy = React.lazy(() => import('./Screens/PrivacyPolicy'));
const NotFound = React.lazy(() => import('./Screens/NotFound'));

function App() {
  return (
    <ScrollOnTop>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<BigLoader />}>
              <HomeScreen />
            </Suspense>
          }
        />

        {/* Projects */}
        <Route
          path="/templates"
          element={
            <Suspense fallback={<BigLoader />}>
              <AllCourses />
            </Suspense>
          }
        />
        {/* templates */}
        <Route
          path="/template/:id"
          element={
            <Suspense fallback={<BigLoader />}>
              <SingleCourse />
            </Suspense>
          }
        />
        <Route
          path="/preview/:id"
          element={
            <Suspense fallback={<BigLoader />}>
              <PreviewPage />
            </Suspense>
          }
        />
        <Route
          path="/category/:name"
          element={
            <Suspense fallback={<BigLoader />}>
              <CategoryPage />
            </Suspense>
          }
        />
        {/* blogs */}
        <Route
          path="/blogs"
          element={
            <Suspense fallback={<BigLoader />}>
              <BlogsScreen />
            </Suspense>
          }
        />
        <Route
          path="/blogs/details/:id"
          element={
            <Suspense fallback={<BigLoader />}>
              <BlogDetails />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<BigLoader />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <Suspense fallback={<BigLoader />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </ScrollOnTop>
  );
}

export default App;
